<template>
  <div class="top-box" v-if="processId">
    <span class="inline"></span>
    <span class="text">工序卡-预览</span>
  </div>
  <div class="box-shadow pt-40 pb-40 pr-40">
    <a-row type="flex" class="mb-20">
      <a-col flex="100px" :offset="2" class="txt-r mr-10">工序卡编号:</a-col>
      <a-col flex="auto">{{ formState.process_num }}</a-col>
    </a-row>
    <a-row type="flex" class="mb-20">
      <a-col flex="100px" :offset="2" class="txt-r mr-10">工序卡名称:</a-col>
      <a-col flex="auto">{{ formState.process }}</a-col>
    </a-row>
    <a-row type="flex" class="mb-20">
      <a-col flex="100px" :offset="2" class="txt-r mr-10">设备名称:</a-col>
      <a-col flex="auto">{{ formState.device }}</a-col>
    </a-row>
    <a-row type="flex" class="mb-20">
      <a-col flex="100px" :offset="2" class="txt-r mr-10">描述:</a-col>
      <a-col flex="auto">{{ formState.content }}</a-col>
    </a-row>
    <a-row type="flex" class="mb-20">
      <a-col flex="100px" :offset="2" class="txt-r mr-10">操作项:</a-col>
      <a-col flex="auto">
        <operation-list-parent
          :unEdit="true"
          :max="2"
          ref="operationRef"
          :data="formState.items"
        ></operation-list-parent>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import operationListParent from '@/views/processManger/components/operationListParent.vue'
import { createProcess, getProcessDes, editProcess } from '@/api'
import { message } from 'ant-design-vue'
import { filterKeys } from '@/hooks/common-hooks'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'ProcessView',
  props: { id: Number },
  components: { operationListParent },
  setup(props, ctx) {
    const router = useRouter()
    const operationRef = ref()
    const formRef = ref()
    const formState = reactive({
      process_num: '',
      process: '',
      device: '',
      content: '',
      items: []
    })
    const processId = computed(() => props.id)
    const disabled = computed(() => {
      if (processId.value) {
        return true
      } else {
        return false
      }
    })
    const rules = {
      process_num: [
        {
          required: true,
          trigger: 'blur',
          whitespace: true
        }
      ],
      process: [
        {
          required: true,
          trigger: 'blur',
          whitespace: true
        }
      ],
      device: [
        {
          required: true,
          trigger: 'blur',
          whitespace: true
        }
      ]
    }
    // 编辑页面时,初始化详情页
    const initData = () => {
      getProcessDes({
        id: props.id
      }).then(res => {
        filterKeys(formState, res.data)
      })
    }
    onMounted(() => {
      if (props.id) {
        initData()
      }
    })
    return {
      formState,
      disabled,
      processId,
      rules,
      operationRef,
      formRef
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/scss/theme.scss';
@import '@/scss/form.scss';
</style>
